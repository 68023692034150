import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  showAnimation = false;
  showLinks = false;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    setTimeout(() => this.showAnimation = true, 100);
    setTimeout(() => this.showLinks = true, 1000);
  }

  navigateToHome() {
    this.showAnimation = false;
    setTimeout(() => this.router.navigate(['/home']), 400);
  }

}
