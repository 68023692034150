import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public imgBrandingSrc = './assets/images/branding.svg';
  public imgIllustrationSrc = './assets/images/illustration.svg';
  public imgMobileSrc = './assets/images/mobile.svg';
  public imgVideoSrc = './assets/images/video.svg';
  public imgPhotographySrc = './assets/images/photography.svg';

  public imgSMBrandingSrc = './assets/images/sm-branding.svg';
  public imgSMIllustrationSrc = './assets/images/sm-illustration.svg';
  public imgSMMobileSrc = './assets/images/sm-mobile.svg';
  public imgSMVideoSrc = './assets/images/sm-video.svg';
  public imgSMPhotographySrc = './assets/images/sm-photography.svg';

  constructor() { }

  ngOnInit() {
  }

}
