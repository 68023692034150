import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  slug: string;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.params.subscribe((p => {
      this.slug = p.slug;
    }));
  }

  ngOnInit() {
  }

}
