import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent implements OnInit {

  slug: string;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.params.subscribe((p => {
      this.slug = p.slug;
    }));
  }

  ngOnInit() {
  }

}
