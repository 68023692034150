import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnimationComponent } from './animation/animation.component';
import { BrandingComponent } from './branding/branding.component';
import { IllustrationComponent } from './illustration/illustration.component';
import { MobileComponent } from './mobile/mobile.component';
import { PhotographyComponent } from './photography/photography.component';
import { VideoComponent } from './video/video.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ProjectInfoComponent } from './project-info/project-info.component';

const routes: Routes = [
  { path: '*', component: LandingPageComponent },
  { path: '', component: LandingPageComponent },
  { path: 'home', component: HomeComponent },
  { path: 'branding', component: BrandingComponent },
  { path: 'illustration', component: IllustrationComponent },
  { path: 'ui-ux', component: MobileComponent },
  { path: 'animation', component: AnimationComponent },
  { path: 'video', component: VideoComponent },
  { path: 'photography', component: PhotographyComponent },
  { path: 'project/:id', component: ProjectInfoComponent },
  { path: 'about', component: AboutUsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
