import { Component, OnInit, Input } from '@angular/core';

import { ProjectsService } from '../shared/services/projects.service';

@Component({
  selector: 'app-thumbnails',
  templateUrl: './thumbnails.component.html',
  styleUrls: ['./thumbnails.component.scss']
})
export class ThumbnailsComponent implements OnInit {
  @Input() categoryId: string;
  @Input() categoryName: string;

  projectList = [];
  loading = true;

  constructor(
    private projectsService: ProjectsService,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.projectsService.get(this.categoryId).subscribe(res => {
      this.projectList = res;
      this.loading = false;
    });
  }

}
