import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogoComponent } from './logo/logo.component';
import { MenuComponent } from './menu/menu.component';
import { BrandingComponent } from './branding/branding.component';
import { IllustrationComponent } from './illustration/illustration.component';
import { MobileComponent } from './mobile/mobile.component';
import { AnimationComponent } from './animation/animation.component';
import { PhotographyComponent } from './photography/photography.component';
import { ThumbnailsComponent } from './thumbnails/thumbnails.component';
import { HomeComponent } from './home/home.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { VideoComponent } from './video/video.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SelectedCategoryComponent } from './selected-category/selected-category.component';
import { GlobalMenuComponent } from './global-menu/global-menu.component';
import { AboutUsComponent } from './about-us/about-us.component';

import { ProjectsService } from './shared/services/projects.service';

@NgModule({
  declarations: [
    AppComponent,
    LogoComponent,
    MenuComponent,
    BrandingComponent,
    IllustrationComponent,
    MobileComponent,
    AnimationComponent,
    PhotographyComponent,
    ThumbnailsComponent,
    HomeComponent,
    ProjectInfoComponent,
    VideoComponent,
    LandingPageComponent,
    SelectedCategoryComponent,
    GlobalMenuComponent,
    AboutUsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
  ],
  providers: [
    ProjectsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
