import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { ProjectsService } from './../shared/services/projects.service';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit {
  project: any;

  constructor(
    private projectService: ProjectsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    public sanitizer: DomSanitizer,
  ) {
    this.activatedRoute.params.subscribe((p => {
      this.projectService.getById(p.id).subscribe(res => {
        if (res) {
          this.project = res;
        } else {
          this.router.navigate(['/home']);
        }
      }, error => {
        this.router.navigate(['/home']);
      });
    }));
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  goBack() {
    this.location.back();
  }

  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('watch?v=', 'embed/'));
  }
}
