import { Http, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class ProjectsService {

  constructor(
    private http: Http,
  ) { }

  public get(categoryId?: string): any {
    return this.http.get('https://admin.mindcelium.com/api/categories/' + categoryId)
      .map((res: Response) => res.json().projects);
  }

  public getById(projectId: string): any {
    return this.http.get('https://admin.mindcelium.com/api/projects/' + projectId)
      .map((res: Response) => res.json());
  }

}
