import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mindcelium';
  public menuIsOpen = false;
  public isLandingPage = false;
  public isAboutPage = false;

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: any) => {
        this.isLandingPage = event.url === '/' ? true : false;
        this.isAboutPage = event.url === '/about' ? true : false;
      });

  }

  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  closeMenu() {
    this.menuIsOpen = false;
  }
}
